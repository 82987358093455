<template>
  <v-container>
    
    <v-row>
      <v-col cols="12">

        <v-row>
          <v-col>
            <v-btn to="/admin" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-banner single-line elevation="2" class="mb-10 mt-3">

          <v-avatar slot="icon" color="blue" size="50" >
            <v-icon dark> mdi-account-supervisor </v-icon>
          </v-avatar>

          <p class="text-h5 text--primary mt-4"> Gestión de Redes Sociales </p>

        </v-banner>

        <v-divider class="my-5"></v-divider>


        <v-row v-if="!isLoading">

          <v-container>

            <!-- Facebook -->
            <v-card class="mb-3">

              <v-card-title class="blue darken-2 white--text mb-4 text-body-1">
                <v-icon dark class="mr-2">mdi-facebook</v-icon>
                Facebook
              </v-card-title>

              <v-card-text>

                <v-switch
                  v-model="socialNetworks.facebook.isEnabled"
                  label="Habilitar visibilidad del botón de Facebook"
                  :readonly="!isModifying || isSaving"
                  dense
                  color="green"
                />
                <v-text-field
                  v-model="socialNetworks.facebook.url"
                  label="URL de Facebook"
                  :disabled="!isModifying || isSaving"
                  filled
                  dense
                  hint="Ingrese la URL de la página de Facebook"
                  persistent-hint
                />

              </v-card-text>

            </v-card>

            <!-- Instagram -->
            <v-card class="mb-3">

              <v-card-title class="orange darken-2 white--text mb-4 text-body-1">
                <v-icon dark class="mr-2">mdi-instagram</v-icon>
                Instagram
              </v-card-title>

              <v-card-text>

                <v-switch
                  v-model="socialNetworks.instagram.isEnabled"
                  label="Habilitar visibilidad del botón de Instagram"
                  :readonly="!isModifying || isSaving"
                  dense
                  color="green"
                />
                <v-text-field
                  v-model="socialNetworks.instagram.url"
                  label="URL de Instagram"
                  :disabled="!isModifying || isSaving"
                  filled
                  dense
                  hint="Ingrese la URL de la página de Instagram"
                  persistent-hint
                />

              </v-card-text>

            </v-card>

            <!-- Youtube -->
            <v-card class="mb-3">

              <v-card-title class="red white--text mb-4 text-body-1">
                <v-icon dark class="mr-2">mdi-youtube</v-icon>
                Youtube
              </v-card-title>

              <v-card-text>

                <v-switch
                  v-model="socialNetworks.youtube.isEnabled"
                  label="Habilitar visibilidad del botón de Youtube"
                  :readonly="!isModifying || isSaving"
                  dense
                  color="green"
                />
                <v-text-field
                  v-model="socialNetworks.youtube.url"
                  label="URL de Youtube"
                  :disabled="!isModifying || isSaving"
                  filled
                  dense
                  hint="Ingrese la URL de la página de Youtube"
                  persistent-hint
                />

              </v-card-text>

            </v-card>


            <!-- Actions -->
            <v-row>

              <v-col v-if="!isModifying" :disabled="isLoading" align="right">
                <v-btn @click="isModifying = true" color="warning">
                  <v-icon class="mr-2">mdi-pen</v-icon>
                  Modificar
                </v-btn>
              </v-col>

              <v-col v-else align="right">
                <v-btn-toggle>
                  <v-btn @click="loadSocialNetworks()" :disabled="isSaving">
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    Volver sin modificar
                  </v-btn>
                  <v-btn @click="updateSocialNetworks()" color="success" :disabled="isSaving">
                    Guardar
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              
            </v-row>

          </v-container>

        </v-row>

        <LoadingIndicator v-else />

      </v-col>
    </v-row>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </v-container>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'

export default {
  name: "AdminSocialNetworks",
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      //state
      isLoading: false,
      isModifying: false,
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //data
      socialNetworks: {
        facebook: {
          url: '',
          isEnabled: false
        },
        instagram: {
          url: '',
          isEnabled: false
        },
        youtube: {
          url: '',
          isEnabled: false
        },
      }
    }
  },
  methods: {
    async loadSocialNetworks() {
      try {

        this.isLoading = true
        this.isModifying = false

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/socialnetworks`)

        if(response.data.data) {
          this.socialNetworks = { ...this.socialNetworks, ...response.data.data }
        }

      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    async updateSocialNetworks() {
      try {

        if(!this.socialNetworks.facebook.url || !this.socialNetworks.instagram.url || !this.socialNetworks.youtube.url) return this.invalidFormNotification()

        this.isSaving = true

        await this.axios.put(`${process.env.VUE_APP_APIURL}/socialnetworks`, this.socialNetworks)

        this.snackcolor = 'success'
        this.snacktext = 'Redes sociales modificadas correctamente'
        this.isSnackbar = true

        this.loadSocialNetworks()

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al modificar las redes sociales'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete todas las URLs'
      this.isSnackbar = true
    },
  },
  mounted() {
    this.loadSocialNetworks()
  }
}
</script>
